import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/layout"
import SEO from "../components/seo"
import EventPhotosGallery from "../components/gallery/event-photos-gallery"
export default function photosGallery() {
    return (
        <Layout>
            <SEO
                title="Gallery | Kentucky Tamil Sangam"
                desc="Gallery | Kentucky Tamil Sangam"
                keywords="Gallery | Kentucky Tamil Sangam"
            />
            <Router>
                <EventPhotosGallery path="/event-gallery/:slug" />
            </Router>
        </Layout>
    );
};
