import React, { useEffect, useState } from "react"
import axios from "axios"
import apiRequest from "../../hooks/HttpRequest"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Link } from "gatsby"
import Slider from "react-slick"
import RecentEvents from "../events/recentEvents"
import { isLoggedIn } from "../../services/auth"
import { Helmet } from 'react-helmet'

//var toShowTicketPurchaseForm = false;

export default function EventPhotosGallery({ slug }) {
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
    }

    const [data, setData] = useState(null)
    const [err, setErr] = useState(false)
    const [toShowTicketPurchaseForm, setToShowTicketPurchaseForm] = useState(false);
    const [toShowEventRegistrationForm, setToShowEventRegistrationForm] = useState(false);
    useEffect(() => {
        axios
            .get(
                apiRequest.baseURL + "/Events/GetEventBySlug/" + slug,
                apiRequest.jsonHeader
            )
            .then((response) => {
                console.log(response.data.Event);
                setData(response.data.Event)
            })
            .catch((e) => {
                setErr(true)
                toast.error(e)
            })
    }, [slug])


    return (
        <>
            <Helmet>
                <title>{data && data.EName}</title>
            </Helmet>
            <div className="page-title-area">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="page-title-content">
                                <h2>Event Details</h2>
                                <ul>
                                    <li>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li>Event Details</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="event-details-area py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="events-details-desc">
                                <div className="causes-details-image mb-4">
                                    <img src={data && data.ImageURL} alt="" />
                                </div>
                                <div className="row mx-0">
                                    <div className="col-12 col-md-12">
                                        <Slider {...settings}>
                                            {data &&
                                                data.EventPhotos.map((eventPhoto, index) => {
                                                    return (
                                                        <div key={index} className="px-1" >
                                                            <img src={eventPhoto.ImageURL} alt="" />
                                                        </div>
                                                    )
                                                })}

                                        </Slider>
                                    </div>
                                </div>
                                <div className="events-details-content">
                                    <div className="entry-meta">
                                        <ul>
                                            <li>
                                                <span>Event Date: &nbsp; </span>
                                                <Link to="/#">{data && new Date(data.EDate).toLocaleDateString("en-US") + " " + data.ETime}</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <h3>{data && data.EName}</h3>
                                </div>
                                <div className="events-details-footer d-none">
                                    <div className="article-tags">
                                        <span>
                                            <i className="bx bx-share" />
                                        </span>
                                        <Link to="/#">Share</Link>
                                    </div>

                                </div>
                                <p>{data && data.Description}</p>
                                <h3>Event Location</h3>
                                <p className="event-text">
                                    {data && data.Location}
                                </p>

                                <div className="map-area d-none">
                                    <iframe title="google map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387190.2799090714!2d-74.25987368715496!3d40.697670064588735!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1570689677254!5m2!1sen!2sbd" />
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-4 col-md-12">
                            <aside className="widget-area" id="secondary">
                                <section className="widget widget_huruma_posts_thumb">
                                    <h3 className="widget-title">Recent Events</h3>
                                    <RecentEvents />
                                </section>
                            </aside>
                        </div>


                    </div>
                </div>
            </section>

            <section className="event-videos py-5">
                <div className="container">

                    <div className="row">
                        {data && data.VideoURLs.length > 10 &&
                            data.VideoURLs.split(",").map((videoURL, index) => {
                                return (
                                    <div key={index} className="col-12 col-md-4 mt-4">
                                        <iframe title="event video"
                                            className="event-video"
                                            src={videoURL.replace("watch?v=", "embed/")}
                                        ></iframe>
                                    </div>
                                )
                            })}
                    </div>
                </div>
            </section>
        </>
    )
}
